<template>
  <div class="p-5 w-70">
    <h1>Privacy Policy</h1>
    <div class="m-4">
      <p>
        This privacy notice discloses the privacy practices for this app owned
        and managed by <b>Vite Technologies Inc.</b>. This privacy notice applies
        solely to information collected by <i>Vite driver app</i>. It will notify you of
        the following:
      </p>
      <ul class="mx-5">
        <li>
          What personally identifiable information is collected from you through
          the website, how it is used and with whom it may be shared.
        </li>
        <li>
          The choices available to you regarding the use of your data.
        </li>
        <li>
          The security procedures in place to protect the misuse of your
          information.
        </li>
        <li>
          How you can correct any inaccuracies in the information.
        </li>
      </ul>
      <h2>Information Collection, Use, and Sharing</h2>
      <div class="p-4">
        <p>
          We are the sole owners of the information collected on this app and
          website.
        </p>
        <p>
          We collect information that you voluntarily give us via email or other
          direct contact from you. We will not sell or rent this information to
          anyone. We will use your information to respond to you regarding the
          reason you contacted us. We will not share your information with any
          third party outside of our organization other than as necessary to
          fulfill your request.
        </p>
        <p>
          Unless you ask us not to, we may contact you in the future to tell you
          about specials, new products or services, or changes to this privacy
          policy.
        </p>
        <p>
          This website uses Google Analytics to help analyze how visitors use
          the site. Google Analytics uses "cookies," which are text files placed
          on your computer, to collect standard internet log information and
          visitor behavior information in an anonymous form. The anonymous
          information generated by the cookie about your use of the website
          (including your IP address) is transmitted to Google. This information
          is then used to evaluate users and to compile statistical reports on
          activity for Vite Technologies only.
        </p>
        <p>
          We will never use Google Analytics to track or to collect any
          personally identifiable information. Google will not associate your IP
          address with any other data held by Google. Neither we nor Google will
          link, or seek to link, an IP address with the identity of a computer
          user. We will not associate any data gathered from this site with any
          personally identifiable information from any source, unless you
          explicitly submit that information via a fill-in form on our website.
        </p>
      </div>
      <h2>
        Links
      </h2>
      <div class="p-4">
        <p>
          This website contains links to other sites. Please be aware that we
          are not responsible for the content or privacy practices of such other
          sites. We encourage our users to be aware when they leave our site and
          to read the privacy statements of any other site that collects
          personally identifiable information.
        </p>
      </div>
      <h2>
        Surveys &amp; Contests
      </h2>
      <div class="p-4">
        <p>
          From time to time our site requests information via surveys or
          contests. Participation in these surveys or contests is completely
          voluntary and you may choose whether or not to participate and
          therefore disclose this information. Information requested may include
          contact information (such as name and shipping address), and
          demographic information (such as zip code, age level). Contact
          information will be used to notify the winners and award prizes.
          Survey information will be used for purposes of monitoring or
          improving the use and satisfaction of this site and our marketing
          efforts in general.
        </p>
      </div>

      <h2>
        Your Access to and Control Over Information
      </h2>
      <div class="p-4">
        <p>
          You may opt out of any future contacts from us at any time. You can do
          the following at any time by contacting us via our contact form or
          phone number given below:
        </p>
        <ul class="mx-5">
          <li>See what data we have about you, if any.</li>
          <li>
            Change/correct any data we have about you.
          </li>
          <li>
            Have us delete any data we have about you.
          </li>
          <li>
            Express any concern you have about our use of your data.
          </li>
        </ul>
      </div>
      <h2>
        Security
      </h2>
      <div class="p-4">
        <p>
          We take precautions to protect your information. When you submit
          sensitive information via the website, your information is protected
          both online and offline.
        </p>
        <p>
          While we use encryption to protect sensitive information transmitted
          online, we also protect your information offline. Only employees who
          need the information to perform a specific job (for example, billing
          or customer service) are granted access to personally identifiable
          information. The computers/servers in which we store personally
          identifiable information are kept in a secure environment.
        </p>
        <p>
          Some of our business partners may use cookies on our site (for
          example, advertisers). However, we have no access to or control over
          these cookies.
        </p>
        <p>
          If you feel that we are not abiding by this privacy policy, you should
          contact us immediately via telephone at
          <a href="tel:+23059150708">23059150708</a> or email:
          <a href="mailto:viteventures@gmail.com"> viteventures@gmail.com</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  font-size: 52px;
  color: #002b54;
}
h2 {
  font-size: 30px;
  color: #73c167;
}
</style>
